@media (min-width: 900px) {
  .mx-w-50 {
    max-width: 50% !important;
  }
}

.cardShadow {
  box-shadow: $shadowBackground;
}

.home {
  width: 100%;
  height: 100vh;

  &-coverImage {
    width: 100%;
    height: auto;
    margin: 0px 0px 15px 0px;
  }
}

.carousel-heigth {
  height: 100%;
}



.containerCardHover {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 500px;
  gap: 10px;

  >div {
    flex: 0 0 120px;
    border-radius: 0.5rem;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    box-shadow: 1px 5px 15px #1e0e3e;
    position: relative;
    overflow: hidden;

    &:nth-of-type(1) {
      background: url("https://images.pexels.com/photos/1845208/pexels-photo-1845208.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") no-repeat 50% / cover;
    }

    &:nth-of-type(2) {
      background: url("https://images.pexels.com/photos/36469/woman-person-flowers-wreaths.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") no-repeat 50% / cover;
    }

    &:nth-of-type(3) {
      background: url("https://images.pexels.com/photos/1468379/pexels-photo-1468379.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") no-repeat 50% / cover;
    }

    &:nth-of-type(4) {
      background: url("https://images.pexels.com/photos/247322/pexels-photo-247322.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") no-repeat 50% / cover;
    }

    .contentCardHover {
      font-size: 1.5rem;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 15px;
      opacity: 0;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      background: rgb(2, 2, 46);
      background: linear-gradient(0deg,
          rgba(2, 2, 46, 0.6755077030812324) 0%,
          rgba(255, 255, 255, 0) 100%);
      transform: translatey(100%);
      transition: opacity 0.5s ease-in-out, transform 0.5s 0.2s;
      visibility: hidden;

      span {
        display: block;
        margin-top: 5px;
        font-size: 1.2rem;
      }
    }

    &:hover {
      flex: 0 0 250px;
      box-shadow: 1px 3px 15px #7645d8;
      transform: translatey(-30px);
    }

    &:hover .contentCardHover {
      opacity: 1;
      transform: translatey(0%);
      visibility: visible;
    }
  }
}


.homeContentContainer {
  // height: 452px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 43px;
}

.galeryHomeContentContainer {
  padding: 80px 16px 16px 16px;
}

.scrollable-container {
  position: relative;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.scrollable-container::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, newer versions of Edge */
}

.scrollable-content {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  // padding: 0 20px;
  /* Add some padding to prevent content from being under the fade */
}

// /* Left fade */
// .scrollable-container::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 30px;
//   background: linear-gradient(to right, white, transparent);
//   z-index: 1;
//   pointer-events: none;
// }

// /* Right fade */
// .scrollable-container::after {
//   content: '';
//   position: absolute;
//   top: 0;
//   right: 0;
//   height: 100%;
//   width: 30px;
//   background: linear-gradient(to left, white, transparent);
//   z-index: 1;
//   pointer-events: none;
// }

.col-auto {
  flex: 0 0 auto;
  padding: 0 10px;
}

/* Default height */
.itemMenuSelected {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  padding-bottom: 5px;
  --line-height: 3px;

}

/* Specific case with different height */
.itemMenuSelected.special-case {
  --line-height: 5px;
}

.itemMenuSelected {
  cursor: pointer;
  position: relative;
  font-size: 20px;
  width: fit-content;
}

.itemMenuSelected::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  /* Adjust the height of the underline as needed */
  background-color: transparent;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.itemMenuSelected.selected::after {
  width: 100%;
  background-color: $primary-golden-text-color !important;
}


@media (min-width : 700px) {
  .itemMenuSelected:hover::after {
    width: 100%;
    background-color: $primary-golden-text-color !important;
    transition: width 0.3s ease, background-color 0.3s ease;
  }

  .itemMenuSelected:hover {
    left: 0;
    color: $primary-golden-text-color !important;
  }
}


.whitePill {
  border: 1px solid white;
  border-width: medium;
  border-radius: 25px;
  text-align: center;
  width: fit-content;
  padding: 1px 50px;
  color: white
}


.blackPill {
  border: 1px solid black;
  border-width: medium;
  border-radius: 25px;
  text-align: center;
  width: fit-content;
  padding: 6px 50px
}

.taleMusical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}

.taleMusicalMobile {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}

.letUsGuideYou {
  position: absolute;
  top: 21%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}

.musicForEveryone {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.experienceDesc {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.testimonyContainer {
  width: 43vw;
}

// @media (max-width : 1200px) {
//   .testimonyContainer {
//     width: 100%;
//   }
// }

@media (max-width : 1200px) {
  .testimonyContainer {
    width: 43vw;
    height: 30vmin;
    overflow: auto;
  }
}

.weOfferContainer {
  width: 370px;
}

@media (max-width : 768px) {
  .weOfferContainer {
    width: 50%;
    font-size: 2vmin;
  }
}


@media (max-width : 765px) {
  .pillButtonFontSize {
    font-size: 2vmin;
  }
}