.landingContiner {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    background-image: url('../../assets/images/backgroundWelcome2.png');
    background-position: center;
}

/* Define the keyframes for the fade-in effect */
@keyframes fadeInLanding {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInWelcome {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOutNode {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-in-image {
    animation: fadeInLanding 0.2s ease-in-out;
    animation-delay: 0.2s;
    opacity: 0.5;
    animation-fill-mode: forwards;
}

.fade-in-image2 {
    animation: fadeInLanding 0.2s ease-in-out;
    animation-delay: 0.4s;
    opacity: 0.5;
    animation-fill-mode: forwards;
}

.fade-in-image3 {
    animation: fadeInLanding 0.2s ease-in-out;
    animation-delay: 0.6s;
    opacity: 0.5;
    animation-fill-mode: forwards;
}

.fade-in-image4 {
    animation: fadeInLanding 0.2s ease-in-out;
    animation-delay: 0.8s;
    opacity: 0.5;
    animation-fill-mode: forwards;
}

.fade-in-image-welcome {
    animation: fadeInWelcome 0.2s ease-in-out;
    opacity: 0;
    animation-fill-mode: forwards;
}

.fadeout-out-node {
    animation: fadeOutNode 1s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.welcomeEntrance {
    animation: fadeInEntrance 2s ease 0s 1 normal forwards;
}

@keyframes fadeInEntrance {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.welcomeExit {
    animation: fadeOutExit 2s ease 0s 1 normal forwards;
}

@keyframes fadeOutExit {
    0% {
        opacity: 1;
        // transform: translateX(0);
    }

    100% {
        opacity: 0;
        // transform: translateX(50px);
    }
}

.buttonEntrance {
    animation: fadeInBottomEntrance 2s ease 0s 1 normal forwards;
}

@keyframes fadeInBottomEntrance {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.buttonExit {
    animation: faceOutTopExit 2s ease 0s 1 normal forwards;
}

@keyframes faceOutTopExit {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-50px);
    }
}

@media (max-width : 375px) {
    .nodeWelcome {
        width: 20vw;
    }
}

@media (max-width : 425px) {
    .welcomeImage {
        width: 100vw;
    }
}