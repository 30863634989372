.NotableConductorContainer::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    /* Black color with 0.5 opacity */
    z-index: 1;
}

.jurist-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.jurist-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 430px;
    /* Adjust this value as needed */
    overflow: hidden;
    margin-bottom: 1.25rem;
}

.jurist-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

@media (max-width : 900px) {
    .jurist-image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        /* Adjust this value as needed */
        overflow: hidden;
        margin-bottom: 1.25rem;
    }
}

.apcsLogoAbout {
    width: 360px;
}

.webringMusicianText {
    font-size: 64px;
}

@media (max-width : 1024px) {
    .apcsLogoAbout {
        width: 70vmin;
    }

    .webringMusicianText {
        font-size: 10vmin;
    }
}


.quote-container {
    position: relative;
}

.quote-text {
    margin: 0;
    max-height: 6em;
    /* Adjust this to control the number of visible lines */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    line-height: 1.5em;
    /* Adjust as needed */
}

.quote-text.expanded {
    max-height: none;
    -webkit-line-clamp: unset;
}

.see-more-btn,
.see-less-btn {
    background: none;
    border: none;
    color: $primary-golden-text-color;
    /* Or any color you prefer */
    cursor: pointer;
    padding: 0;
    font-size: 0.9em;
    margin-left: 5px;
}

.see-more-btn {
    display: inline;
}

.see-less-btn {
    display: block;
    margin-top: 5px;
}