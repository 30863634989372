.carousel-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.AchieversCoverText {
  position: absolute;
  top: 62%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.registerButtonContainer-leftSide {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.registerButtonContainer-leftSide-Bottom {
  position: absolute;
  // top: 120%;
  transform: translateY(-150%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.closeMute {
  position: absolute;
  transform: translate(-38px, 0px);
}

.registerButtonContainer-center-top {
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}

.registerButtonContainer-center50 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registerButtonContainer-bottom {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width : 1000px) {
  .registerButtonContainer-bottom {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.registerButtonContainer-center {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registerButtonContainer-center-left {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registerButtonContainer-centerLeft {
  position: absolute;
  top: 50%;
  right: 25%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}

.registerButtonContainer-centerLeft40 {
  position: absolute;
  top: 40%;
  right: 25%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel-prev {
  font-size: 24px;
  cursor: pointer;
  margin-left: 25px;
  z-index: 2;
}

.carousel-next {
  font-size: 24px;
  cursor: pointer;
  margin-right: 25px;
  z-index: 2;
}

.titleCoverContainer {
  display: flex;
  position: absolute;
  // top: 75%;
  left: 0;
  transform: translate(50%, 50%);
  width: 100%;
  /* Span the entire width of the relative container */
  text-align: center;
  /* Center the text horizontally */
  z-index: 1;
  pointer-events: none;
}

@media (max-width : 1445px) {
  .titleCoverContainer {
    display: flex;
    position: absolute;
    // top: 75%;
    left: 0;
    transform: translate(50%, 10vmin);
    width: 100%;
    /* Span the entire width of the relative container */
    text-align: center;
    /* Center the text horizontally */
    z-index: 1;
    pointer-events: none;
  }
}

.titleCoverContainer-Banner {
  display: flex;
  position: absolute;
  top: 75%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  /* Span the entire width of the relative container */
  text-align: center;
  /* Center the text horizontally */
  z-index: 10;
  // pointer-events: none;
}

.titleCoverContainerText {
  color: white;
}

.slider-nav {
  display: flex;
  column-gap: 1rem;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.slider-nav-leftSide {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.slider-nav-dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.75;
  transition: opacity ease 250ms;
}

.registerButtonContainer {
  display: flex;
  column-gap: 1rem;
  position: absolute;
  bottom: 12%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.carousel-image {
  flex: 1 0 100%;
  scroll-snap-align: start;
  object-fit: contain;
  max-height: 100%;
  max-width: 100vw;
  width: auto;
  height: auto;
  transform: translateX(calc(-100% * var(--currentIndex)));
  transition: transform ease 1200ms;
}

.titleCoverContainerText {
  flex: 1 0 100%;
  scroll-snap-align: start;
  transform: translateX(calc(-100% * var(--currentIndex)));
  transition: transform ease 1200ms;
  z-index: 2;

}

.carouselContainer {
  scrollbar-width: none;
  display: flex;
  position: relative;
  overflow-x: hidden;
}