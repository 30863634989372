@font-face {
  font-family: "mangolaine";
  src: local("mangolaine"),
    url("./assets/fonts/mangolaine/mangolaine.ttf") format("truetype");
}

@font-face {
  font-family: "mosafin";
  src: local("mosafin"),
    url("./assets/fonts/mosafin/Mosafin-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "mosafin", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

div {
  /* overflow-x: hidden !important; */
}