@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

.plus-jakarta-sans-font {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

/* Navbar styles */
.navbarContainerMobile {
    // width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    // padding-left: 18rem !important;
    // padding-right: 18rem !important;
    text-align: center;
    color: white;
    // position: fixed;
    z-index: 1002;
    // background: black;
    position: absolute;
}

.menuNavbarContainer {
    list-style-type: none;
    // remove display flex later
    display: none;
    height: 100%;
    gap: 1rem;
}

.logoContainerMobile {
    display: none;
}

@media (max-width : 992px) {
    .menuNavbarContainer {
        list-style-type: none;
        display: flex;
        height: 100%;
        gap: 1rem;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 2000;
        width: 300px;
        background-color: black;
        transform: translate(300px, 0px);
        transition: transform 0.3s ease-in-out;
        padding: 26px 16px 0px 16px
    }

    .menuNavbarContainer.open {
        transform: translate(0px, 0px);
    }

    // .logoContainerNavbar {
    //     display: none;
    // }

    .logoContainerMobile {
        display: contents;
    }
}