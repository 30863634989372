.overflow-x-hidden {
  overflow-x: hidden;
}

.video-container {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100vw;
  height: 100%;
  // object-fit: cover;
  // z-index: -10;
}

.video-container-home {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  // object-fit: cover;
  // z-index: -10;
}

.homeScreenLogo {
  width: 40vmin;
  // position: absolute;
  // transform: translate(-243px, -101px);
}

.experinceText {
  font-size: 96px;
  letter-spacing: 25px;
}

.homeScreenLogoMobile {
  width: 20vmax;
}

@media (max-width : 600px) {
  .homeScreenLogo {
    width: 20vmax;
    // position: absolute;
    // transform: translate(-243px, -101px);
  }
}

@media (max-width : 1200px) {
  .experinceText {
    font-size: 5vmin;
    letter-spacing: 0px;
  }

  .experienceContentText {
    font-size: 2vmin;
  }
}

.homeScreenimage {
  // position: absolute;
  // top: 58px;
  // left: 0;
  width: 100%;
  // height: 100vh;
  object-fit: cover;
  // z-index: -10;
}

.CoverImageContainer {
  // position: absolute;
  left: 0;
  width: 100vw;
  // height: 100vh;
  object-fit: cover;
  z-index: -10;
}

//   .video-container video {
//     object-fit: cover;
//     width: 100%;
//     height: 100%;
//   }

/* Content styles */
.content-container {
  padding-left: 18rem;
  padding-right: 18rem;
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.content-container .text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.content-container .text-neutral-200 {
  color: rgb(229 229 229);
  margin-top: 1.25rem;
}

.readMoreButtonContainer {
  width: fit-content;
  padding: 12px;
  background-color: black;
  border: 2px solid black;
  color: white;
  transition: background-color 0.2s, border-color 0.2s;
  margin-top: 1.25rem;
}

.readMoreButtonContainer:hover {
  background-color: transparent;
  border-color: white;
  transition: background-color 0.2s, border-color 0.2s;
}