.cardContainer {
    border: 1px solid black;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    /* or .5rem if you prefer */
    background-color: white;
    cursor: move;
}

.dayContainer {
    border: 1px solid black;
    padding: 8px;
}