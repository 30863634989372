#image-track {
    display: flex;
    gap: 4vmin;
    user-select: none; /* -- Prevent image highlighting -- */
    // left: 50%;
    // top: 50%;
    // transform: translate(0%, -50%); 
    // position: absolute;
}
  
#image-track > .image {
    width: 40vmin;
    height: 56vmin;
    object-fit: cover;
    object-position: 100% center;
}
  