.imageSize {
    &-icon {
        width: 25px;
        height: 25px;
    }

    &-logoTitle {
        width: 150px;
        height: 150px;
        align-self: center;
    }
}

.fontSizeHeader {
    font-size: 64px;
}

.fontSizeSubHeader {
    font-size: 30px;
}

.fontSizeBody {
    font-size: 16px;
}

@media (max-width : 900px) {

    .fontSizeHeader {
        font-size: 32px;
    }

    .fontSizeSubHeader {
        font-size: 20px;
    }

    .fontSizeBody {
        font-size: 14px;
    }
}

@media (min-width : 900px) {
    .fontSizeDesktopOnlyHeader {
        font-size: 20px;
    }
}

.loadingContainer {
    height: 100vh;
    text-align-last: center;
    align-content: center;
}

.textColor {
    color: white;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.fullScreenImage {
    object-fit: cover;
    width: 100vw;
    height: 101vh;
}

.fullScreenHeight {
    width: 100%;
    // height: 100vh;
}

.required:after {
    content: " *";
    color: red;
}

.cursorPointer {
    cursor: pointer;
}

.align-self-center {
    align-self: center;
}

.place-content {
    &-end {
        place-content: end;
    }
}

.flex {
    display: flex;

    &-column {
        display: flex;
        flex-direction: column;
    }
}

.justify {
    &-evenly {
        justify-content: space-evenly;
    }

    &-around {
        justify-content: space-around;
    }

    &-center {
        justify-content: center;
    }

    &-spaceBetween {
        justify-content: space-between;
    }
}

.text {
    &-align {
        &-start {
            text-align: start;
        }

        &-end {
            text-align: end;
        }

        &-center {
            text-align: center;
        }

        &-justify {
            text-align: justify;
        }

        &-last {
            &-end {
                text-align-last: end;
            }
        }
    }
}

.w-10 {
    width: 10%;
}

.w-14 {
    width: 14%;
}

.w-15 {
    width: 15%;
}

.w-17 {
    width: 17%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-100 {
    width: 100%;
}

.full-w {
    width: 100vw;
}

.full-h {
    height: 100vh;
}

.h-100 {
    height: 100%;
}

.p-4 {
    padding: 4px;
}

.p-8 {
    padding: 8px;
}

.p-16 {
    padding: 16px;
}

.p-24 {
    padding: 24px;
}

.pt-8 {
    padding-top: 8px;
}

.pt-16 {
    padding-top: 16px;
}

.pt-24 {
    padding-top: 24px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-120 {
    padding-top: 120px;
}

.pr-8 {
    padding-right: 8px;
}

.pr-16 {
    padding-right: 16px;
}

.pr-24 {
    padding-right: 24px;
}

.pl-8 {
    padding-left: 8px;
}

.pl-12 {
    padding-left: 12px;
}

.pl-16 {
    padding-left: 16px;
}

.pl-24 {
    padding-left: 24px;
}

.pb-8 {
    padding-bottom: 8px;
}

.pb-16 {
    padding-bottom: 16px;
}

.pb-24 {
    padding-bottom: 24px;
}

.pb-40 {
    padding-bottom: 40px;
}

.m-4 {
    margin: 4px;
}

.m-8 {
    margin: 8px;
}

.m-16 {
    margin: 16px;
}

.m-24 {
    margin: 24px;
}

.mt-4 {
    margin-top: 4px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-40 {
    margin-top: 40px;
}

.mr-2 {
    margin-right: 2px;
}

.mr-4 {
    margin-right: 4px;
}

.mr-8 {
    margin-right: 8px;
}

.mr-16 {
    margin-right: 16px;
}

.mr-24 {
    margin-right: 24px;
}

.mr-40 {
    margin-right: 40px;
}

.ml-2 {
    margin-left: 2px;
}

.ml-4 {
    margin-left: 4px;
}

.ml-8 {
    margin-left: 8px;
}

.ml-16 {
    margin-left: 16px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-24 {
    margin-left: 24px;
}

.ml-32 {
    margin-left: 32px;
}

.ml-64 {
    margin-left: 64px;
}

.ml-146 {
    margin-left: 146px;
}

.mb-2 {
    margin-bottom: 2px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-40 {
    margin-bottom: 40px;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}


.place {
    &-items {
        &-center {
            place-items: center;
        }
    }
}

.textColorSelected {
    color: $primary-golden-text-color;
}

.separator::before {
    content: "|";
    margin: 0 5px;
}

.align {
    &-items {
        &-center {
            align-items: center;
        }

        &-end {
            align-items: flex-end;
        }

        &-start {
            align-items: flex-start;
        }
    }

    &-self {
        &-center {
            align-self: center;
        }
    }

    &-content {
        &-center {
            align-content: center;
        }
    }
}

.font {
    &-weight {
        &-normal {
            font-weight: 400;
        }

        &-bold {
            font-weight: bold;
        }
    }
}

.color {
    &-primaryDark {
        color: $primary-color-dark
    }

    &-white {
        color: white;
    }
}

.primaryBackgroundBlack {
    background-color: $primary-color-dark;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    backdrop-filter: blur(2px);
    transition: opacity 0.3s ease;
}

.backgroundBlack {
    background-color: black;
}

.centerScreen {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.nowrap {
    text-wrap: nowrap;
}

.mangolaineFont {
    font-family: "mangolaine";
}

.italicText {
    font-style: italic;
}

.mosafinFont {
    font-family: "mosafin" !important;
}

.lineHeightNormal {
    line-height: normal;
}

.goldenTextColor {
    color: $primary-golden-text-color;
}

.boxed-text {
    // max-width: 600px;
    /* Adjust the max-width to your desired width */
    margin: 0 auto;
    /* Center the text block */
    text-align: justify;
    /* Justify the text to align both left and right edges */
    padding: 20px;
    /* Add padding to create space around the text */
    border-radius: 8px;
    /* Optional: Add border radius for rounded corners */
}

.fadeDarkImageTopBottom {
    content: "";
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 96vh;
    // background: linear-gradient(to bottom,
    //     rgba(0, 0, 0, 1) 0%,
    //     rgba(0, 0, 0, 1) 10%,
    //     rgba(0, 0, 0, 0) 50%,
    //     rgba(0, 0, 0, 1) 80%,
    //     rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 10%,
            rgba(0, 0, 0, 0) 35%,
            rgba(0, 0, 0, 0) 60%,
            rgba(0, 0, 0, 1) 85%,
            rgba(0, 0, 0, 1) 100%);
    pointer-events: none;
}


// if use imageContainer by default should use height auto
.autoHeight {
    height: auto;
}

.image-container-fadedTopBottom {
    position: relative;
    // display: inline-block;
}

.image-container-fadedTopBottom img {
    display: block;
    width: 100%;
    // height: auto;
}

.image-container-fadedTopBottom::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,
            rgb(0, 0, 0) 7%,
            rgba(0, 0, 0, 0) 15%,
            rgba(0, 0, 0, 0) 70%,
            rgb(0, 0, 0) 92%);
    pointer-events: none;
}




// Mobile container utility class:
.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.textWithShadow {
    // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    // text-shadow: 4px 4px 6px rgba(0, 0, 0, 1);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* xs */
@media (min-width: 475px) {
    .container {
        max-width: 475px;
    }
}

/* sm */
@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

/* md */
@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

/* lg */
@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

/* xl */
@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

/* 2xl */
@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}

// Desktop container utility class:
// .container {
//   max-width: 1536px;
//   margin-left: auto;
//   margin-right: auto;
//   padding-left: 0.5rem;
//   padding-right: 0.5rem;
// }

// /* 2xl */
// @media (max-width: 1536px) {
//   .container {
//     max-width: 1280px;
//   }
// }

// /* xl */
// @media (max-width: 1280px) {
//   .container {
//     max-width: 1024px;
//   }
// }

// /* lg */
// @media (max-width: 1024px) {
//   .container {
//     max-width: 768px;
//   }
// }

// /* md */
// @media (max-width: 768px) {
//   .container {
//     max-width: 640px;
//   }
// }

// /* sm */
// @media (max-width: 640px) {
//   .container {
//     max-width: 475px;
//   }
// }

// /* xs */
// @media (max-width: 475px) {
//   .container {
//     width: 100%;
//   }
// }