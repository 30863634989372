.seats {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.seats>h3 {
    color: black;
    margin-top: 20px;
}

.screens {
    margin-top: 30px;
    background-color: black;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen {
    color: red;
}

.seat-price {
    margin-top: 30px;
    display: flex;
    background-color: rgb(83, 199, 235);
    width: 80%;
    justify-content: center;
    align-items: center;
}

.seat-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.seats-select {
    font-size: 20px;
    margin-left: 20px;
}

.price {
    font-size: 20px;
}

.totalprice {
    margin-left: 50%;
}

.continue {
    background-color: rgb(9, 41, 51);
    margin-top: 20px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    color: forestgreen;
}