// .image-gallery {
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   // max-width: 50rem;
//   margin-inline: auto;
//   margin-top: 16px;
//   margin-bottom: 16px;
// }

// .image-gallery>img {
//   // width: 100%;
//   // aspect-ratio: 1 / 1;
//   // object-fit: cover;

//   transition: scale 350ms ease, opacity 350ms linear;
// }

// .image-gallery:has(img:hover) img:not(:hover) {
//   scale: 0.8;
//   opacity: 0.7;
// }


// .image-galleryDynamic>div {
//   columns: 3;
//   gap: 1.25rem;
//   padding-top: 1.25rem;
// }

.image-galleryDynamic img {
  //   display: block;
  //   width: 100%;
  //   border-radius: 5px;
  //   margin-bottom: 1.25rem;

  transition: scale 350ms ease, opacity 350ms linear;
}

// @media (max-width:48rem) {
//   .image-galleryDynamic>div {
//     columns: 2;
//   }
// }

// @media (max-width:32rem) {
//   .image-galleryDynamic>div {
//     columns: 1;
//   }
// }

.image-galleryDynamic:has(img:hover) img:not(:hover) {
  scale: 0.8;
  opacity: 0.7;
}


.image-gallery {
  /* Mobile first */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-gallery .column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-item img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;

  transition: scale 350ms ease, opacity 350ms linear;
}

@media only screen and (min-width: 768px) {
  .image-gallery {
    flex-direction: row;
  }
}

.masonry-img {
  width: 100%;
  height: auto;
  display: block;
}


.gallery-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* Adjust the gap between rows */
}

.gallery-row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  /* Adjust the gap between items in a row */
}

.gallery-item {
  flex: 1;
}

.gallery-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

// .gallery-item {
//   aspect-ratio: 16 / 9;
//   /* Or any ratio you prefer */
// }

// .gallery-img {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }


.feature-name {
  white-space: nowrap;
}

@media only screen and (max-width: 480px) {
  .feature-name {
    white-space: normal;
    word-break: auto-phrase;
  }
}